import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LinkIcon = (props) => (
  <SvgIcon {...props}>
  <path d="M10.7,10.5c-0.3-0.7-0.8-1.3-1.5-1.7c1.9-0.7,3.9-0.7,5.8,0c-0.7,0.4-1.2,1-1.6,1.7C12.5,10.4,11.6,10.4,10.7,10.5z
	 M2.9,15.4c0.6,0.2,1.3,0.4,2.1,0.4c1.2,0,2-0.1,2.6-0.3C7.9,15.4,8,15,7.8,14.8c-0.4-0.3-0.7-0.7-0.9-1c-0.1-0.1-0.2-0.2-0.4-0.2
	c-2.2,0.3-3.9-0.2-4-1.5c0.1-1.1,1.3-1.5,2.8-1.6c1.7,0,2.8,0.4,3.6,1.3c0.1,0.6,0.8,0.9,1.6,1.2c0.7-2.6-0.9-4.7-5-4.7
	c-0.7,0-1.3,0.1-1.8,0.2C3.1,8.6,2.5,8.8,1.9,9.2C1,9.7,0.5,10.7,0.4,12c0.1,1,0.4,1.8,1,2.4C1.8,14.8,2.3,15.1,2.9,15.4z
	 M13.2,15.6c2.8-0.4,4.6-2.3,3.8-4.6c-0.7,0.2-1.4,0.5-1.7,1.1c-0.5,1.1-1.7,1.3-3,1.5c-1.6,0-3-0.3-3.7-1.3C8.3,11.5,7.7,11.2,7,11
	c-0.8,2.2,1,4.2,4.3,4.6C12,15.7,12.6,15.7,13.2,15.6z M22.6,14.3c0.6-0.6,0.9-1.4,1-2.4c-0.1-1.3-0.6-2.2-1.5-2.8
	c-0.5-0.3-1.1-0.5-1.7-0.7c-0.5-0.1-1.2-0.2-1.8-0.2c-4.1,0-5.7,2.2-5,4.7c0.7-0.2,1.4-0.5,1.5-1.2c0.7-0.8,1.9-1.3,3.6-1.3
	c1.5,0.1,2.7,0.4,2.8,1.6c-0.1,1.4-1.8,1.8-4,1.5c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.4-0.5,0.7-0.9,1c-0.2,0.2-0.2,0.6,0.2,0.7
	c0.6,0.2,1.4,0.3,2.6,0.3c0.8,0,1.5-0.2,2.1-0.4C21.7,15.1,22.2,14.8,22.6,14.3z"/>
  </SvgIcon>
)

export default LinkIcon;