import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddBoozulaIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M22.2,2.2H2c-0.4,0-0.7,0.3-0.6,0.6C2,5.6,4.5,16.1,5.4,20c0.2,1,0.9,1.7,1.8,1.9c3.2,0.7,6.4,0.7,9.6,0
	c0.9-0.2,1.7-0.9,1.9-1.8c0.9-3.9,3.4-14.5,4-17.2C22.8,2.5,22.5,2.2,22.2,2.2z M14.5,16l-1.4,0c0,0-0.1,0-0.1,0.1l0,1.5
	c0,0.6-0.5,0.9-1,1c-0.6,0-0.9-0.5-1-1v-1.4c0,0,0-0.1-0.1-0.1H9.5c-0.6,0-0.9-0.5-1-1c0-0.6,0.5-0.9,1-1h1.4c0,0,0.1,0,0.1-0.1
	v-1.4c0-0.6,0.5-0.9,1-1c0.6,0,1,0.4,1,1v1.4c0,0,0,0.1,0.1,0.1h1.4c0.6,0,0.9,0.5,1,1C15.5,15.7,15,16,14.5,16z M19.3,9.4
	c-4.9-0.9-9.8-0.9-14.7,0L3.2,3.7h17.6L19.3,9.4z"/>
	</SvgIcon>
)

export default AddBoozulaIcon;