import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmberIcon = (props) => (
    <SvgIcon {...props}>
<path d="M21.3,6.3c-0.3,2.5-2.4,4.1-4.6,5.8c-0.5,2.3-3.6,5.1-5.8,4.3c-2.1-1.1-2.4,1.5-1.3,2c1.7,0.4,2.7,0.7,5.7-1.9
	c1.3-0.9,2.4-2.1,3.6-3.8c-0.3,1.7-1.3,3.2-2.7,4.3c-1.5,1.5-1.5,2.1-0.4,1.9c0.4-0.1,1-0.7,1.5-1.4c-0.5,1.7-2,3.4-3.4,4.5
	c-3.7,2.7-7.1,1.9-9.7,0c-2.6-2-3.7-4.1-3.2-7.5c0.8-2.8,1.6-4.8,4.9-6.4c0.9-0.4,0.2-1,1.9-2.6C10.1,3,13.9,6,17.7,2.2
	C17.6,3.1,17.1,3.9,16,5c2.6-0.3,5-1.7,7.1-4.9c-0.7,2.9-2.3,5.6-5.7,7.3c-1.6,1.1-3.6,1.7-5.8,2.2c-1.1,0.2-2.3,0.7-3.3,1.3
	C6.7,12,6,13.2,6,14.4c1.3-1.6,3.5-3.3,6.8-1.7C14.1,9.2,19.1,9.1,21.3,6.3z"/>
    </SvgIcon>
)

export default EmberIcon;