import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const InfernalIcon = (props) => (
  <SvgIcon {...props}>
<path d="M20.7,18.8c-0.6,0.6-1.3,1.1-1.7,1.2c-0.1,0.2-0.3,0.3-0.4,0.5c-2.5,2.5-6.9,4.7-4.5,2.1c2.1-2.3,2.5-5.3,1.2-7.6
	c-0.2-0.2-2-2.5-2.1-2.7c-0.3-0.4,0.2,2.2,0,1.7c-1.7-4-2.8-3.1-2.9-3.1c0,0,2,1.3,0.2,3.5c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.4,0.6-0.7,1.3-1,2c-0.7,1.8-0.7,4,1.1,6c1.4,1.9-2.3-1-4.3-2.8c-0.4-0.1-1.2-0.4-1.8-0.9c-2.3-1.8-2.3-5.7-2.1-5.9
	c0,0.7,0.4,0.9,0.7,0.9c0.5-0.9,0-1.4,0-1.4c-1-1-0.1-3-0.2-2.3c-0.1,0.5,0,0.9,0.5,0.6c0.8-0.2-0.6-0.2,0-1.7
	c0.4-1,1.5-1.4,1.6-1.5C3.9,9.3,4.3,9.9,5,10.3C5.1,10,5.3,9.9,5.5,9.7C5.3,8.6,5.2,7.4,5.9,7.1C8,6.2,7,4.8,7,4.8
	c0.3-0.3,1,0.3,1.5,1c1-0.9,2.2-2.3,1.2-2.5c-1.7-0.3-1.9-1.9-1-3.1c0.1,0,0.7,2.5,1.9,1.5c0.6-0.5,1.5,0.5,2.1,1
	c2.6,1.9,1.7,2.9,1.5,3.1c0.8-0.5,1.3-0.7,0.7-1.4c-0.8-1.1,3.3,1.5,1.6,4.1c2.6-2.5-0.9,1.2,1.2,2.4c0.1-0.1,0.2-0.3,0.4-0.5
	c0.7-0.5,1.1-1.2-0.1-2.5c0,0,1.3,0.2,1.8,1.1c0.7,1,0.5,1.5,0.4,1.7c0.3-0.1,0.4-0.2,0.3-0.6c-0.2-0.7,1.1,1.1,0.3,2.3
	c0,0.1-0.4,0.6,0.3,1.3c0.3-0.1,0.7-0.3,0.5-1C21.8,12.8,22.7,16.6,20.7,18.8z M10.9,22.1c0.5-0.1-0.6-1.2-0.2-1.4
	c1.4-0.5,0.6-3.2,0.5-3.8c0.6,0.9,2.9,2.3,1.8,4.3c-1.2,1.4,0,2.1,0,2.1C12.9,24,10.7,22.7,10.9,22.1z"/>
  </SvgIcon>
)

export default InfernalIcon;