import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HowlIcon = props => (
    <SvgIcon {...props}>
    <path d="M10.8,8.1h8.1c2.3,0,4.1,1.9,4.1,4.1v1.6c0,2.3-1.9,4.1-4.1,4.1h-0.8l-3,4.1c-0.2,0.3-0.7,0.1-0.6-0.3l0.9-3.9H9.2
	c-2.3,0-4.1-1.9-4.1-4.1l-0.8-0.5l0,0l-1-2l0,0L2.9,5.7l0,0C2.6,4.9,1.9,4,1.7,3.9c-0.1-0.5,0.2-1,0.3-1c0.6,0.2,1,0.3,1.5,0.2
	L3.4,2.5C3.1,1.8,3.3,1.3,3.7,0.8c0.2-0.2,0.5-0.3,0.8-0.3l1.8,2.2c0,0,0,0,0.1,0c0.3,0,0.7,0,0.9,0.1C7.6,3.1,8,3.1,8.6,3.7
	c0.7,0.6,1.4,1,1.8,2.1c0,0,0,0,0.1,0c0.7,0,2.3,0.9,2.1,1.2c-0.2,0.1-1.2,0.6-1.7,0.5c-0.2,0.1-0.2,0.2-0.2,0.2L10.8,8.1z M7.3,4.4
	c0,0-0.3-0.5-0.3-0.7c0-0.3-0.5-0.3-0.5-0.3C6.3,3.8,6.8,4.4,7.3,4.4z"/>
	</SvgIcon>
)

export default HowlIcon;