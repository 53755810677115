import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddVideoIcon = props => (
    <SvgIcon {...props}>
<path d="M21.6,0.2H2.4c-1,0-1.9,0.8-1.9,1.9v19.8c0,1,0.8,1.9,1.9,1.9h19.1c1,0,1.9-0.8,1.9-1.9V2.1C23.4,1.1,22.6,0.2,21.6,0.2z
	 M21.4,19.7c0,1-0.8,1.9-1.9,1.9h-15c-1,0-1.9-0.8-1.9-1.9V4.2c0-1,0.8-1.9,1.9-1.9h15c1,0,1.9,0.8,1.9,1.9V19.7z M19.5,2.3h-15
	c-1,0-1.9,0.8-1.9,1.9v1.4h18.7V4.2C21.4,3.2,20.5,2.3,19.5,2.3z M5.5,4.3C5.5,4.7,5.2,5,4.9,5H4.1C3.7,5,3.5,4.7,3.5,4.3V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M8.5,4.3C8.5,4.7,8.2,5,7.9,5H7.1C6.7,5,6.5,4.7,6.5,4.3V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M11.5,4.3c0,0.3-0.3,0.6-0.6,0.6h-0.8C9.7,5,9.5,4.7,9.5,4.3V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M14.5,4.3c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M17.5,4.3c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M20.5,4.3c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V3.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6V4.3z M2.6,19.7c0,1,0.8,1.9,1.9,1.9h15c1,0,1.9-0.8,1.9-1.9v-1.4H2.6V19.7z
	 M18.5,19.5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V19.5z
	 M15.5,19.5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V19.5z
	 M12.5,19.5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V19.5z
	 M9.5,19.5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6h-0.8c-0.3,0-0.6-0.3-0.6-0.6V19.5z
	 M6.5,19.5c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6H7.1c-0.3,0-0.6-0.3-0.6-0.6V19.5z M3.5,19.5
	c0-0.3,0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6v0.8c0,0.3-0.3,0.6-0.6,0.6H4.1c-0.3,0-0.6-0.3-0.6-0.6V19.5z M12,7.3
	c-2.6,0-4.7,2.1-4.7,4.7s2.1,4.7,4.7,4.7s4.7-2.1,4.7-4.7S14.6,7.3,12,7.3z M13.6,12.3l-2.3,1.3c-0.3,0.2-0.6,0-0.6-0.4l0-2.6
	c0-0.3,0.3-0.5,0.6-0.4l2.3,1.3C13.9,11.7,13.9,12.1,13.6,12.3z"/>
    </SvgIcon>
)

export default AddVideoIcon;