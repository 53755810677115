import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FuseRequestIcon = props => (
    <SvgIcon {...props}>
        <path d="M5.6,19.7l-2.2,3.2C2,9.6,2.9,1.1,18.4,4.3l2.2-3.2C22,14.4,21.3,22.8,5.6,19.7z M16.1,7.2l-1,1.4c-6.8-1.4-7.8,2.2-7.2,8.1
	l1-1.4C15.7,16.7,16.7,13.1,16.1,7.2z M11.1,12.8H9.9c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.2c0,0,0.1,0,0.1-0.1V9.9
	c0-0.5,0.4-0.8,0.8-0.8l0,0c0.5,0,0.8,0.4,0.8,0.8v1.2c0,0,0,0.1,0.1,0.1h1.2c0.5,0,0.8,0.4,0.8,0.8l0,0c0,0.5-0.3,0.8-0.8,0.8h-1.2
	c0,0-0.1,0-0.1,0.1v1.2c0,0.5-0.4,0.8-0.8,0.8l0,0c-0.5,0-0.8-0.4-0.8-0.8L11.1,12.8C11.2,12.9,11.1,12.8,11.1,12.8z"/>
    </SvgIcon>
)

export default FuseRequestIcon;