import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CheersIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M23,17.7c-1.5,0.9-3.1,1.4-4.9,1.6c-0.5,0-1-0.2-1.2-0.7c-1.1-1.8-4.1-6.7-4.9-8c-0.1-0.1,0-0.3,0.2-0.4l10.2-3.3
	c0.2,0,0.4,0.1,0.4,0.3c0.1,1.5,0.6,7.2,0.8,9.4C23.8,17,23.5,17.4,23,17.7z M22.1,7.8l-8.9,2.9l1.1,1.7c2.3-1.3,5.6,0,8.2-0.4
	L22.1,7.8z M5.6,19.3c-1.7-0.2-3.4-0.7-4.9-1.6c-0.4-0.2-0.7-0.7-0.6-1.3C0.4,14.3,0.8,8.6,1,7.1c0-0.2,0.2-0.3,0.4-0.2l10.2,3.3
	c0.2,0,0.3,0.3,0.1,0.4c-0.7,1.3-3.8,6.2-4.9,8C6.6,19.1,6.1,19.3,5.6,19.3z M10.6,10.7L1.7,7.8l-0.4,4.5c2.6,0.4,5.8-1.1,8.2,0.1
	L10.6,10.7z M7.1,13.3c1.3-0.1,2.4,0.9,2.4,2.2l0,1.7l1.1-1.3c0.8-1,2.4-1,3.3-0.1L15,17l-0.1-1.7c-0.1-1.3,0.9-2.4,2.2-2.4l1.7,0
	l-1.3-1.1c-1-0.8-1-2.4-0.1-3.3l1.2-1.2l-1.7,0.1c-1.3,0.1-2.4-0.9-2.4-2.2l0-1.7L13.4,5c-0.8,1-2.4,1-3.3,0.1L9,3.9l0.1,1.7
	C9.3,6.9,8.2,8,6.9,8l-1.7,0L6.5,9c1,0.8,1,2.4,0.1,3.3l-1.2,1.2L7.1,13.3z"/>
    </SvgIcon>
)

export default CheersIcon;