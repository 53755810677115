import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CandleIcon = (props) => (
  <SvgIcon {...props}>
  <path d="M14.9,3.1c0.1,0.1-0.7-0.3-0.6,0.7c0.1,1.3,0.7,0.2,0.8,0c0.8-1.3,1.1,2.7,0.3,3.7C13.7,9.4,13,9.8,13,9.8
	c1.2-1.7,1.5-3.3-0.1-4.5c-1.5,1.1-2,2.6-1.1,4.5c0,0,0.2,0.2-1.8-1.6c-2-1.6-0.8-4,1-5.9c0.5-0.6-0.4-1.9,0.2-2.2
	C11.3,0.2,14.2,1.8,14.9,3.1z M11.8,8v3.2l1-0.2V7l0,0C12.2,7,11.8,7.4,11.8,8z M9.1,12.8v9.9c0,0.6,0.5,1,1,1h4.4c0.6,0,1-0.5,1-1
	V12c0-0.6-0.6-1.1-1.2-1l-4.4,0.9C9.4,11.9,9.1,12.4,9.1,12.8z M9.1,12.7v0.9c0,1-0.3,2.2-0.8,2.9c0,0.7,0.7,1,0.8,0.4V12.7z
	 M15.4,20.2c0,1.3,0.7,0.7,0.7-0.8c-0.5-1.2-0.5-3.4-0.5-5.6l-0.3-2L15.4,20.2z"/>
  </SvgIcon>
)

export default CandleIcon;