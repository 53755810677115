import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResetPWIcon = props => (
    <SvgIcon {...props}>
    <path d="M18.5,2.1l-5.9,4.6c-3.1,0-4.5,1.4-5,3.9l0,0l0,0c-0.3,1.4-0.3,3.2-0.1,5.2l1.1-1.5c1.7,0.3,3,0.4,4.1,0.2l4.9,3.7
	C15,19.8,11,20.2,5.1,19l-2.4,3.5C1.1,8.1,1.9-1,18.5,2.1z M17,8.4c-0.8,0.1-2.5-0.4-2.4-1.2c0,0,0.2-2.5,4-2.4s4.8,2,4.1,3.7
	s-4,1.8-4,2.9c0.2,1.6,0.3,1.5,0,1.8c-0.5,0.5-1.1,0.5-1.4-0.2c0,0-0.6-1.7,0.2-2.8s2.7-1.4,3.3-2.4c0.6-1,0.2-1.8-2.2-2
	C16.1,5.6,17,8.4,17,8.4z M19,15.7c0,0.6-0.5,0.9-1,0.9c-0.6,0-0.9-0.5-0.9-1c0-0.5,0.5-0.9,1-0.9C18.7,14.6,19.1,15.1,19,15.7z"/>
    </SvgIcon>
)

export default ResetPWIcon;