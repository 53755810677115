import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EditDetailsIcon = (props) => (
  <SvgIcon {...props}>
  <path d="M18.5,2.1l-5.9,4.6c-3.1,0-4.5,1.4-5,3.9l0,0l0,0c-0.3,1.4-0.3,3.2-0.1,5.2l1.1-1.5c1.7,0.3,3,0.4,4.1,0.2l4.9,3.7
	c-2.6,1.6-6.6,2-12.5,0.8l-2.4,3.5C1.1,8.1,1.9-1,18.5,2.1z M20.6,5.7l-6,2.5C14.1,8.5,13.9,9,14,9.5l0.5,1.3
	c0.2,0.5,0.7,0.7,1.2,0.6l6-2.5c0.8-0.3,1.1-1.2,0.8-2l-0.2-0.3C22.2,5.7,21.3,5.3,20.6,5.7z M12.5,9.1l-1.7,1.7
	c-0.4,0.4-0.1,1.1,0.4,1l2.3,0.1c0.4,0,0.7-0.3,0.5-0.7l-0.7-1.9C13.2,8.9,12.7,8.8,12.5,9.1z"/>
  </SvgIcon>
)

export default EditDetailsIcon;