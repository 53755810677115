import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EmptyIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M15.7,0.4c2.3,1,4.2,2.5,5.8,4.4c0.5,0.5,0.6,1.3,0.2,2c-1.2,2.8-4.5,10.2-5.4,12.2c-0.1,0.2-0.4,0.3-0.6,0.1L3.5,9.8
	c-0.2-0.1-0.2-0.5,0-0.7c1.6-1.4,7.9-6.6,10.2-8.6C14.3,0.2,15.1,0.1,15.7,0.4z M5,9.6l10.6,8l0.3-0.8c-2.4-1.2,3.1-3.2,1.9-8.3
	c-0.7-2.9,3.4-2.4-3-7.3L5,9.6z M15.1,18.9c0,0-1.8,3.5-1,4.3s1.6,0.5,2.1-0.2c0.2-0.3,0.2-1.1-0.3-1.5
	C15.4,21.1,15.1,18.9,15.1,18.9z"/>
    </SvgIcon>
)

export default EmptyIcon;