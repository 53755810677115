import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HowlEditIcon = props => (
    <SvgIcon {...props}>
        <path d="M18,9.5c-0.2,0-0.2,0-0.2,0c-0.7-1.9-1.9-2.5-3.1-3.6c-1-1-1.7-1-2.2-1.5c-0.3-0.2-1-0.2-1.5-0.2c-0.2,0-0.2,0-0.2,0l-3-3.7
	c-0.5,0-1,0.2-1.4,0.5C5.8,1.8,5.5,2.7,6,3.9l0.2,1c-0.9,0.2-1.5,0-2.6-0.3c-0.2,0-0.7,0.8-0.5,1.7c0.3,0.2,1.5,1.7,2,3.1v9.2
	l2.3,3.6l1.5,0.7c6.3,1,9.7-9.5,9.7-9.5l-0.2-0.7c0,0,0-0.2,0.3-0.3c0.9,0.2,2.6-0.7,2.9-0.9C21.9,11,19.2,9.5,18,9.5z M10,17.7
	l-2.1,0.8c-0.4,0.3-1-0.2-0.8-0.8L8,15.5c0.1-0.4,0.6-0.5,0.8-0.1l1.4,1.5C10.5,17.1,10.4,17.5,10,17.7z M16.3,11.6l-4.5,4.7
	c-0.4,0.3-1,0.3-1.3-0.1l-1-1c-0.3-0.4-0.3-1,0-1.4L14,9.1c0.5-0.7,1.5-0.7,1.9,0.1l0.3,0.2C16.8,10.1,16.9,11,16.3,11.6z"/>
    </SvgIcon>
)

export default HowlEditIcon;