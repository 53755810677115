import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const EditBoozulaIcon = (props) => (
  <SvgIcon {...props}>
  <path d="M16.8,21.9c-3.2,0.7-6.4,0.7-9.6,0C6.3,21.7,5.6,21,5.4,20C4.5,16.1,2,5.6,1.4,2.8C1.3,2.5,1.6,2.2,2,2.2h20.2
	c0.3,0,0.6,0.3,0.5,0.7c-0.6,2.7-3.1,13.3-4,17.2C18.5,21,17.7,21.7,16.8,21.9z M20.8,3.7H3.2l1.4,5.7c4.9-0.9,9.8-0.9,14.7,0
	L20.8,3.7z M15.4,10.7L10.6,15c-0.4,0.4-0.4,0.9-0.1,1.3l0.9,1c0.4,0.4,0.9,0.4,1.3,0.1l4.8-4.3c0.7-0.5,0.7-1.4,0.1-2.1l-0.3-0.3
	C16.9,10.2,15.9,10.1,15.4,10.7z M8.9,16.4l-1,2.1c-0.3,0.5,0.3,1,0.6,0.8l2.2-0.6c0.4-0.1,0.5-0.5,0.3-0.8l-1.3-1.6
	C9.6,16,9.1,16,8.9,16.4z"/>
  </SvgIcon>
)

export default EditBoozulaIcon;