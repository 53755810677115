import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddImageIcon = (props) => (
    <SvgIcon {...props}>
<path d="M21.6,0.2H2.4c-1,0-1.9,0.8-1.9,1.9v19.8c0,1,0.8,1.9,1.9,1.9h19.1c1,0,1.9-0.8,1.9-1.9V2.1C23.4,1.1,22.6,0.2,21.6,0.2z
	 M21.4,19.7c0,1-0.8,1.9-1.9,1.9h-15c-1,0-1.9-0.8-1.9-1.9V4.2c0-1,0.8-1.9,1.9-1.9h15c1,0,1.9,0.8,1.9,1.9V19.7z M21.4,14.3
	L21.4,14.3v5.4c0,1-0.8,1.9-1.9,1.9h-15c-1,0-1.9-0.8-1.9-1.9v-5.4h8.6l1.7-1.5c1.2-1,2.9-1.3,4.4-0.5L21.4,14.3z M4.5,5.7
	c0-1.1,0.9-1.9,1.9-1.9s1.9,0.9,1.9,1.9S7.5,7.6,6.4,7.6S4.5,6.8,4.5,5.7z M13.8,7c-1.1,0-2,0.3-2,0.6c0,0.3,0.9,0.6,2,0.6
	s2-0.3,2-0.6C15.8,7.3,14.9,7,13.8,7z M14.5,5.4c-0.8,0-1.4,0.5-1.4,1.2s0.6,1.2,1.4,1.2c0.8,0,1.4-0.5,1.4-1.2S15.3,5.4,14.5,5.4z
	 M16.6,6.9c-1.1,0-2,0.5-2,1.2s0.9,1.2,2,1.2c1.1,0,2-0.5,2-1.2S17.7,6.9,16.6,6.9z M15.9,6.6c-0.3,0-0.6,0.1-0.6,0.3
	c0,0.2,0.3,0.3,0.6,0.3s0.6-0.1,0.6-0.3C16.6,6.8,16.3,6.6,15.9,6.6z M13.8,7c-0.6,0-1,0.5-1,1s0.5,1,1,1s1.7-0.5,1.7-1
	S14.3,7,13.8,7z"/>
    </SvgIcon>
)

export default AddImageIcon;