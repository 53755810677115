import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const StokeIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M8.6,23.3c-0.7,0-1.1-0.5-1.1-1.1v-3.4H3c-1.2,0-2.3-1-2.3-2.3v-1.6V13v-2.6V7.8V5.9V3c0-1.2,1-2.3,2.3-2.3h2.8h2.7h1.7h2.3
	h1.4h1.4h1.9h2h1.1H21c1.2,0,2.3,1,2.3,2.3v1.5v1.9v1.9v2.6V13v3.5c0,1.2-1,2.3-2.3,2.3h-6.9L10,23c-0.2,0.2-0.5,0.3-0.8,0.3H8.6
	 M10.5,4.1c-0.7,0.4,0.5,2.1-0.2,2.8C8,9.2,6.5,12.2,8.8,14.1c2.6,2.2,2.3,2,2.3,2c-1-2.4-0.4-4.2,1.4-5.6c2.1,1.6,1.7,3.5,0.2,5.6
	c0,0,0.9-0.5,3.1-2.9c1-1.2,0.6-6.2-0.3-4.6c-0.1,0.3-0.8,1.7-1,0.1c-0.2-1.3,0.9-0.7,0.7-0.9C14.3,6.2,10.7,4.2,10.5,4.1z"/>
    </SvgIcon>
)

export default StokeIcon;