import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddTextIcon = props => (
    <SvgIcon {...props}>
        <path d="M21.6,0.2H2.4c-1,0-1.9,0.8-1.9,1.9v19.8c0,1,0.8,1.9,1.9,1.9h19.1c1,0,1.9-0.8,1.9-1.9V2.1C23.4,1.1,22.6,0.2,21.6,0.2z
	 M21.4,19.7c0,1-0.8,1.9-1.9,1.9h-15c-1,0-1.9-0.8-1.9-1.9V4.2c0-1,0.8-1.9,1.9-1.9h15c1,0,1.9,0.8,1.9,1.9V19.7z M19.3,6.7h-6.9
	c-0.5,0-0.9-0.4-0.9-0.9v0C11.6,5.3,12,5,12.4,5h6.9c0.5,0,0.9,0.4,0.9,0.9v0C20.2,6.3,19.8,6.7,19.3,6.7z M19.5,9.7H8.6
	c-0.5,0-0.9-0.4-0.9-0.9v0C7.8,8.3,8.2,8,8.6,8h10.9c0.5,0,0.9,0.4,0.9,0.9v0C20.4,9.3,20,9.7,19.5,9.7z M19.5,12.7H4.6
	c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h14.9c0.5,0,0.9,0.4,0.9,0.9v0C20.4,12.3,20,12.7,19.5,12.7z M19.5,12.7H4.6
	c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h14.9c0.5,0,0.9,0.4,0.9,0.9v0C20.4,12.3,20,12.7,19.5,12.7z M19.5,15.7H4.6
	c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h14.9c0.5,0,0.9,0.4,0.9,0.9v0C20.4,15.3,20,15.7,19.5,15.7z M19.5,18.7H4.6
	c-0.5,0-0.9-0.4-0.9-0.9v0c0-0.5,0.4-0.9,0.9-0.9h14.9c0.5,0,0.9,0.4,0.9,0.9v0C20.4,18.3,20,18.7,19.5,18.7z"/>
    </SvgIcon>
)

export default AddTextIcon;