import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SizzleIcon = props => (
  <SvgIcon {...props}>
  <path d="M4.9,12c0,2.3-0.9,4.5-2.5,6.1l-1,0.9l3.8-1.6c0.9-0.4,2,0.2,1.5,0.8l-1.7,4l2.7-2.6C8.4,19,9.8,19.3,9.8,20v3.9l1.4-3.4
	c0.3-0.7,1.9-0.7,2.2,0l1.5,3.3v-3.9c-0.1-0.7,1.4-1,2-0.4l2.6,2.4L17.7,18c-0.3-0.7,0.7-1.2,1.7-0.9l3.6,1.4l-0.9-0.8
	c-1.6-1.5-2.6-3.6-2.6-5.8l0,0c0-2.2,0.9-4.4,2.5-5.9l0.7-0.8l-3.5,1.4c-0.8,0.4-1.9-0.2-1.7-0.8l1.7-3.9l-2.6,2.5
	C15.9,5,14.5,4.7,14.5,4V0.1l-1.4,3.3c-0.3,0.7-1.9,0.7-2.2,0L9.4,0.1L9.5,4c0.1,0.7-1.4,1-2,0.4L4.8,1.8l1.9,4C7,6.5,6,7,5.1,6.7
	L1.2,5.2l1,0.9C3.9,7.6,4.9,9.8,4.9,12L4.9,12z M12.3,13.7h-0.5c-0.7,0-1.2-0.5-1.2-1.2V7.2c0-0.7,0.5-1.2,1.2-1.2h0.5
	c0.7,0,1.2,0.5,1.2,1.2v5.3C13.5,13.1,13,13.7,12.3,13.7z M12.4,17.4h-0.7c-0.5,0-1-0.4-1-1v-0.7c0-0.5,0.4-1,1-1h0.7
	c0.5,0,1,0.4,1,1v0.7C13.4,16.9,13,17.4,12.4,17.4z"/>
  </SvgIcon>
);

export default SizzleIcon;