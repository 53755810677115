import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ToastIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M8.6,23.5h0.6c0.3,0,0.6-0.1,0.8-0.3l4.3-4.3h7c1.3,0,2.3-1,2.3-2.3V13v-2.2V8.2v-2v-2V2.8c0-1.3-1-2.3-2.3-2.3h-0.8h-1.1
	h-2.1h-2h-1.4h-1.4h-2.3H8.4H5.7H2.8c-1.3,0-2.3,1-2.3,2.3v3v2v2.6V13v2v1.6c0,1.3,1,2.3,2.3,2.3h4.6v3.5C7.4,23,7.9,23.5,8.6,23.5
	 M14.6,14.6c-1.6,0.3-3.1,0.3-4.7,0c-0.4-0.1-0.8-0.4-0.9-0.9c-0.4-1.9-1.7-7-2-8.4C7,5.1,7.2,5,7.4,5h9.9c0.1,0,0.3,0.1,0.2,0.3
	c-0.3,1.3-1.5,6.5-2,8.4C15.4,14.2,15,14.5,14.6,14.6z M16.6,6H8l0.7,2.8c2.4-0.4,4.8-0.4,7.2,0L16.6,6z"/>
    </SvgIcon>
)

export default ToastIcon;