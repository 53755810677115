// User Reducer Types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_SIZZLES_READ = 'MARK_SIZZLES_READ';
export const MARK_CLINKS_READ = 'MARK_CLINKS_READ';
// UI Reducer Types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_SUCCESS = 'SET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';
// Data Reducer Types
export const SET_SPARKS = 'SET_SPARKS';
export const SET_SPARK = 'SET_SPARK';
export const EXTINGUISH_SPARK = 'EXTINGUISH_SPARK';
export const POST_SPARK = 'POST_SPARK';
export const LOADING_DATA = 'LOADING_DATA';
export const ADD_STOKE = 'ADD_STOKE';
export const ADD_HEAT = 'ADD_HEAT';
export const REMOVE_HEAT = 'REMOVE_HEAT';
export const SET_OKELISTS = 'SET_OKELISTS';
export const SET_BOOZULAS = 'SET_BOOZULAS';
export const SET_OKELIST = 'SET_OKELIST';
export const SET_BOOZULA = 'SET_BOOZULA';
export const ADD_CHEERS = 'ADD_CHEERS';
export const REMOVE_CHEERS = 'REMOVE_CHEERS';
export const EMPTY_BOOZULA = 'EMPTY_BOOZULA';
export const CHANGE_BOOZ_IMAGE = 'CHANGE_BOOZ_IMAGE';
export const BUILD_BOOZULA = 'BUILD_BOOZULA';
export const BUILD_OKE = 'BUILD_OKE';
export const ERASE_OKE = 'ERASE_OKE';
export const ADD_SONG = 'ADD_SONG';
export const ADD_TOAST = 'ADD_TOAST';
export const CHOOZ_BY_LIST = 'CHOOZ_BY_LIST';
export const SET_INFERNALS = 'SET_INFERNALS';
export const SET_FUSERS = 'SET_FUSERS';
export const SET_SENT_REQUESTS = 'SET_SENT_REQUESTS';
export const SEND_REQUEST = 'SEND_REQUEST';
export const FETCH_REQUESTED = 'FETCH_REQUESTED';
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';
export const REJECT_REQUEST = 'REJECT_REQUEST';
export const CANCEL_REQUEST = 'CANCEL_REQUEST';
export const SILENCE_FUSER = 'SILENCE_FUSER';
export const UNSILENCE_FUSER = 'UNSILENCE_FUSER';
export const GET_SILENCED_LIST = 'GET_SILENCED_LIST';
export const SET_HOWLS = 'SET_HOWLS';
export const SET_HOWL = 'SET_HOWL';
export const POST_HOWL = 'POST_HOWL';
export const SET_FUSER_HOWLS = 'SET_FUSER_HOWLS';
export const SET_FUSER = 'SET_FUSER';
export const ERASE_HOWL = 'ERASE_HOWL';
export const SET_COUNT = 'SET_COUNT';
export const ADD_COUNT = 'ADD_COUNT';
export const SET_SPARK_ID = 'SET_SPARK_ID';
export const POST_SPARK_IMAGE = 'POST_SPARK_IMAGE';
export const POST_SPARK_VIDEO = 'POST_SPARK_VIDEO';
export const POST_SPARK_AUDIO = 'POST_SPARK_AUDIO';
export const SHARE_EMBER = 'SHARE_EMBER';
