import React from 'react';
import SvgIcon from  '@material-ui/core/SvgIcon';

const CloseIcon = (props) => (
    <SvgIcon {...props}>
    <path d="M19.5,2.2l2.2,2.2c0.7,0.7,0.7,1.9,0,2.7l-3.4,3.4c-7.1,0.6-5.3,1.9,0,2.7l3.4,3.4c0.7,0.7,0.7,1.9,0,2.7l-2.2,2.2
	c-0.7,0.7-1.9,0.7-2.7,0L13.3,18c-0.8-6.5-2-6.4-2.7,0l-3.4,3.4c-0.7,0.7-1.9,0.7-2.7,0l-2.2-2.2c-0.7-0.7-0.7-1.9,0-2.7l3.5-3.5
	c1.6-0.8,9-1.5,0-2.6L2.4,6.9C1.7,6.2,1.7,5,2.5,4.3l2.2-2.2c0.7-0.7,1.9-0.7,2.7,0l3.4,3.4c0.5,6.4,1.7,6.6,2.7,0l3.3-3.3
	C17.5,1.5,18.7,1.5,19.5,2.2z"/>
    </SvgIcon>
)

export default CloseIcon;