import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OkelistIcon = (props) => (
  <SvgIcon {...props}>
  <path d="M6.6,4.2l7.1-0.3l0,0c1.3-0.1,2.5,0,3.2-1.1l0.7-1.2L6.1,2.1L6.6,4.2z M19.7,13.9c-2.4-0.5-4.7,0.7-5.1,2.6
	c-0.4,2,1.2,4,3.6,4.5s4.7-0.7,5.1-2.6C23.7,16.4,22.1,14.4,19.7,13.9z M23.3,17.3c0,0.2,0-0.3-0.1-0.5L20.1,1.9
	c-0.2-0.7-0.9-1.2-1.6-1l0,0c-0.7,0.2-1.2,0.9-1,1.6L19.9,14C19.8,13.9,22.6,14.5,23.3,17.3z M5.8,13.8c-2.4-0.5-4.7,0.7-5.1,2.6
	c-0.4,2,1.2,4,3.6,4.5s4.7-0.7,5.1-2.6C9.8,16.3,8.1,14.3,5.8,13.8z M4.5,0.8C3.8,1,3.3,1.7,3.5,2.4l2.4,11.5
	c-0.1-0.1,2.7,0.5,3.4,3.3c0,0.2,0-0.3-0.1-0.5L6.1,1.8C5.9,1.1,5.3,0.6,4.5,0.8L4.5,0.8 M1.4,16.7C2,15.3,3,14.5,4.7,14.5
	C3.3,14.9,2.2,15.6,1.4,16.7z M15.3,16.8c0.7-1.5,1.7-2.3,3.3-2.2C17.3,15,16.2,15.7,15.3,16.8z M16.5,6.8l-8,0.4
	c-0.4,0-0.8-0.3-0.8-0.7v0C7.7,6,8,5.6,8.5,5.6l8-0.4c0.4,0,0.8,0.3,0.8,0.7v0C17.3,6.4,17,6.8,16.5,6.8z M17.3,9.7l-8,0.4
	c-0.4,0-0.8-0.3-0.8-0.7v0c0-0.4,0.3-0.8,0.7-0.8l8-0.4c0.4,0,0.8,0.3,0.8,0.7v0C18.1,9.3,17.8,9.7,17.3,9.7z M17.9,12.6l-8,0.4
	c-0.4,0-0.8-0.3-0.8-0.7v0c0-0.4,0.3-0.8,0.7-0.8l8-0.4c0.4,0,0.8,0.3,0.8,0.7v0C18.7,12.2,18.4,12.6,17.9,12.6z"/>
  </SvgIcon>
)

export default OkelistIcon;