import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FireIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M6.6,0.3c0.1,0,3.8-0.1,5.8,1.4C15.6,4,13.8,5,14,4.9c0.9-0.5,1.4-0.7,0.8-1.5c-0.9-1.2,3.5,1.6,1.7,4.3
	c2.8-2.6-1,1.3,1.3,2.5c0.9-0.4,1.8-1,1.1-2.3c0.7,0.2,4.7,7.3-0.1,12.4c-2.7,2.6-7.4,4.9-4.8,2.2c2.3-2.4,2.7-5.5,1.3-7.9
	c-0.2-0.2-2.1-2.6-2.3-2.8c-0.3-0.4,0.2,2.3,0,1.8c-1.8-4.2-3-3.3-3.1-3.3c0,0,2.1,1.4,0.2,3.7C8.6,16.1,7,19.6,10,22.7
	c1.8,2.3-4.1-2.2-5.6-3.9c-3.2-4-1.6-8.6,0.9-10.3c2.7-1.8,0-1.9,2.5-3.4C9.7,3.7,10.6,2.2,6.6,0.3z"
    />
  </SvgIcon>
);

export default FireIcon;