import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SelfieIcon = (props) => (
  <SvgIcon {...props}>
	<path d="M23.6,12c0,6.5-5.2,11.7-11.6,11.7S0.4,18.5,0.4,12S5.6,0.3,12,0.3S23.6,5.5,23.6,12z M12,1.9C6.5,1.9,2,6.4,2,12
	s4.5,10.1,10,10.1S22,17.6,22,12S17.5,1.9,12,1.9z M12,4c-2,0-3.6,1.7-3.6,3.7s1.6,3.7,3.6,3.7s3.6-1.7,3.6-3.7S14,4,12,4z
	 M18.3,18.8c0.6-0.6,0.7-1.5,0.2-2.1c-1.1-1.7-3.4-4.9-6.5-4.9c-3.1,0-5.5,3.2-6.6,4.9C5,17.5,5.1,18.4,5.7,19
	c1.6,1.6,3.7,2.2,6,2.5c0.2,0,0.4,0,0.6,0C14.5,21.1,16.7,20.4,18.3,18.8z"/>
  </SvgIcon>
)

export default SelfieIcon;