import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const AddAudioIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M21.6,0.2H2.4c-1,0-1.9,0.8-1.9,1.9v19.8c0,1,0.8,1.9,1.9,1.9h19.1c1,0,1.9-0.8,1.9-1.9V2.1C23.4,1.1,22.6,0.2,21.6,0.2z
	 M21.4,19.7c0,1-0.8,1.9-1.9,1.9h-15c-1,0-1.9-0.8-1.9-1.9V4.2c0-1,0.8-1.9,1.9-1.9h15c1,0,1.9,0.8,1.9,1.9V19.7z M11.3,18.8
	l-3.7-3.2c-0.1-0.1-0.3-0.2-0.5-0.2l-1.6,0.1c-1.3,0.1-2.3-0.9-2.3-2.2v-2.8c0-1.3,1.1-2.3,2.4-2.2L7,8.6c0.2,0,0.4,0,0.5-0.2
	l3.8-3.3c0.4-0.4,1.1-0.1,1.1,0.5v12.7C12.4,18.9,11.7,19.2,11.3,18.8z M13.3,10.1l0.9-1c1.6,1.8,1.6,3.6,0,5.6l-0.9-0.9
	C14.3,12.6,14.4,11.3,13.3,10.1z M16,16.7l-0.9-0.9c2.1-2.5,2.1-5,0-7.5L16,7.3C18.7,10.4,18.8,13.5,16,16.7z M17.9,18.5L17,17.6
	c3-3.8,3.1-7.6,0-11.3l0.9-0.9C21.6,9.8,21.6,14.2,17.9,18.5z M18,5.9c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7
	s0.3-0.6,0.7-0.6C17.8,5.2,18,5.5,18,5.9z M18.1,18.1c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.6,0.7-0.6
	C17.8,17.5,18.1,17.8,18.1,18.1z M16.2,7.8c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.6,0.7-0.6
	C15.9,7.2,16.2,7.4,16.2,7.8z M16.2,16.2c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.6,0.7-0.6
	C15.9,15.5,16.2,15.8,16.2,16.2z M14.4,9.7c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7S13.4,9,13.7,9C14.1,9,14.4,9.3,14.4,9.7z
	 M14.3,14.4c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.6,0.7-0.6C14,13.7,14.3,14,14.3,14.4z"/>
    </SvgIcon>
)

export default AddAudioIcon;